import React, { useState } from 'react'
import { Container, Form, Button } from 'react-bootstrap'
import styles from '../styles/Home.module.css'
import { ToastContainer, toast } from 'react-toastify'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import AdditionalScripts from '../utils/AdditionalScripts'
import Google from '../../public/svgs/Google.svg'
import Head from 'next/head'

const Login = () => {
  let [google, setGoogle] = useState(false)
  let [googleValue, setGoogleValue] = useState('')
  let [oDemo, setOdemo] = useState({
    sd: '',
  })

  const onChangeoDemo = (e) => {
    e.preventDefault()
    setOdemo({
      ...oDemo,
      [e.target.name]: e.target.value,
    })
  }

  const OnlineDemo = (e) => {
    e.preventDefault()
    //if (oDemo.sd !== "" && oDemo.un !== "" && oDemo.pwd !== "") {
    if (oDemo.sd !== '') {
      window.open(`https://${oDemo.sd}.webhr.co/`, '_blank')
    } else {
      toast('Please enter your WebHR URL')
    }
    // }
  }

  const ForgetPassword = (e) => {
    e.preventDefault()
    if (oDemo.sd !== '') {
      window.open(`https://${oDemo.sd}.webhr.co/hr/login/?page=ForgotPassword`, '_blank')
    } else {
      toast('Please enter your WebHR URL')
    }
  }

  const intuit = (e) => {
    e.preventDefault(e)
    window.open('https://appcenter.intuit.com/connect/oauth2', 600, 500)
  }

  const googleBtnClick = (e) => {
    e.preventDefault(e)
    setGoogle(!google)
  }

  const googleSubmit = (e) => {
    e.preventDefault(e)
    if (googleValue !== '') {
      window.open(`https://webhr.co/hr/login/googleapps.php?action=Check&domain=${googleValue}`, 600, 500)
    }
  }

  return (
    <>
      <Head>
        <meta property="og:title" content="WebHR | Online Login" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://web.hr/login" />
        <meta property="og:image" content="https://web.hr/WebHR_OG.png" />
        <meta property="og:description" content="Login to WebHR - Web Human Resource | WebHR will streamline your organization's HR policies by implementing the best practices from around the globe!" />
        <meta property="og:site_name" content="WebHR" />

        <meta name="description" content="Login to WebHR - Web Human Resource | WebHR will streamline your organization's HR policies by implementing the best practices from around the globe!" />
        <title>WebHR | Online Login</title>
        <AdditionalScripts />
      </Head>
      <div style={{ marginBottom: '5em' }}>
        {!google ? (
          <Container className={styles.stepsForm} style={{ width: '380px' }}>
            <div>
              <h1 className={styles.heading}>WebHR Login</h1>
              <h4 style={{ color: '#646464' }}>Employee Login</h4>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1em',
                }}
              >
                <div style={{ width: '100%' }}>
                  <Form onSubmit={OnlineDemo}>
                    <div style={{ display: 'flex' }}>
                      <span className={styles.domainStyle} style={{ marginRight: '1em' }}>
                        https://
                      </span>
                      <Form.Control type="text" placeholder="demo" name="sd" value={oDemo.sd} onChange={onChangeoDemo} />
                      <span className={styles.domainStyle} style={{ marginLeft: '1em' }}>
                        .webhr.co/
                      </span>
                    </div>

                    <br />
                    <Button className={styles.primaryBtnBanner2} type="submit" style={{ width: '100%', minHeight: '40px' }}>
                      Login
                    </Button>
                    <p onClick={ForgetPassword} style={{ padding: '1em 0 0 0', cursor: 'pointer' }}>
                      Forget Password ?
                    </p>
                    <button className={styles.googleBtn} style={{ background: '#236CFF', marginBlock: 10 }} onClick={intuit}>
                      <div style={{ marginInline: 10, marginTop: 2, color: 'white' }}>Sign In with Intuit</div>
                    </button>

                    <button className={styles.googleBtn} onClick={googleBtnClick}>
                      <Google />
                      <div style={{ marginInline: 10, marginTop: 2, color: '#9DA1A6' }}>Sign In with Google</div>
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <Container className={styles.stepsForm} style={{ width: '380px' }}>
            <div>
              <h3 className={styles.heading}>WebHR Google Login</h3>
              <div className={styles.decoraion} style={{ width: '100px' }}></div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1em',
                }}
              >
                <div style={{ width: '100%' }}>
                  <Form onSubmit={googleSubmit}>
                    <label>Google Apps Domain</label>
                    <div style={{ display: 'flex' }}>
                      <Form.Control type="text" name="googleValue" value={googleValue} onChange={(e) => setGoogleValue(e.target.value)} />
                    </div>
                    <br />
                    <button className={styles.googleBtn}>
                      <Google />
                      <div style={{ margin: '1px 0 0 5px' }}>Sign In with Google</div>
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </Container>
        )}

        <ToastContainer position="bottom-left" autoClose={2500} hideProgressBar newestOnTop={false} closeOnClick={false} rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover={false} />
      </div>
    </>
  )
}

export default Login
